<template>
  <b-card-code title="Position">
    <b-tabs>
      <!-- top position -->
      <b-tab title="Top">
        <b-tabs content-class="mt-2">
          <b-tab title="Tab 1">
            <b-card-text>
              Oat cake marzipan cake lollipop caramels wafer pie jelly beans. Icing halvah chocolate cake carrot cake. Jelly
              beans carrot cake marshmallow gingerbread chocolate cake. Sweet fruitcake cheesecake biscuit cotton candy.
              Cookie powder marshmallow donut. Gummies cupcake croissant.
            </b-card-text>
          </b-tab>
          <b-tab active title="Tab 2">
            <b-card-text>
              Sugar plum tootsie roll biscuit caramels. Liquorice brownie pastry cotton candy oat cake fruitcake jelly chupa
              chups. Sweet fruitcake cheesecake biscuit cotton candy. Cookie powder marshmallow donut. Pudding caramels
              pastry powder cake soufflé wafer caramels. Jelly-o pie cupcake.
            </b-card-text>
          </b-tab>
          <b-tab title="Tab 2">
            <b-card-text>
              Biscuit ice cream halvah candy canes bear claw ice cream cake chocolate bar donut. Toffee cotton candy
              liquorice. Oat cake lemon drops gingerbread dessert caramels. Sweet dessert jujubes powder sweet sesame snaps.
              Sweet fruitcake cheesecake biscuit cotton candy. Cookie powder marshmallow donut.
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-tab>

      <!-- right position -->
      <b-tab title="Right">
        <b-tabs vertical end nav-class="nav-right" nav-wrapper-class="nav-vertical">
          <b-tab active title="Tab 1">
            <b-card-text>
              Oat cake marzipan cake lollipop caramels wafer pie jelly beans. Icing halvah chocolate cake carrot cake. Jelly
              beans carrot cake marshmallow gingerbread chocolate cake. Sweet fruitcake cheesecake biscuit cotton candy.
              Cookie powder marshmallow donut. Gummies cupcake croissant.
            </b-card-text>
            <b-card-text>
              Sweet chocolate muffin fruitcake gummies jujubes pie lollipop. Brownie marshmallow caramels gingerbread jelly
              beans chocolate bar oat cake wafer.
            </b-card-text>
          </b-tab>
          <b-tab title="Tab 2">
            <b-card-text>
              Sugar plum tootsie roll biscuit caramels. Sweet fruitcake cheesecake biscuit cotton candy. Cookie powder
              marshmallow donut. Liquorice brownie pastry cotton candy oat cake fruitcake jelly chupa chups. Pudding caramels
              pastry powder cake soufflé wafer caramels. Jelly-o pie cupcake.
            </b-card-text>
            <b-card-text>
              Sweet chocolate muffin fruitcake gummies jujubes pie lollipop. Brownie marshmallow caramels gingerbread jelly
              beans chocolate bar oat cake wafer.
            </b-card-text>
          </b-tab>
          <b-tab title="Tab 3">
            <b-card-text>
              Sweet fruitcake cheesecake biscuit cotton candy. Cookie powder marshmallow donut. Biscuit ice cream halvah
              candy canes bear claw ice cream cake chocolate bar donut. Toffee cotton candy liquorice. Oat cake lemon drops
              gingerbread dessert caramels. Sweet dessert jujubes powder sweet sesame snaps.
            </b-card-text>
            <b-card-text>
              Candy cookie sweet roll bear claw sweet roll. Cake tiramisu cotton candy gingerbread cheesecake toffee cake.
              Cookie liquorice dessert candy canes jelly.
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-tab>

      <!-- bottom position -->
      <b-tab title="Bottom">
        <b-tabs end>
          <b-tab title="Tab 1" active>
            <b-card-text>
              Pudding candy canes sugar plum cookie chocolate cake powder croissant. Carrot cake tiramisu danish candy cake
              muffin croissant tart dessert.
            </b-card-text>
            <b-card-text> Tiramisu caramels candy canes chocolate cake sweet roll liquorice icing cupcake. </b-card-text>
          </b-tab>
          <b-tab title="Tab 2">
            <b-card-text>
              Candy canes donut chupa chups candy canes lemon drops oat cake wafer. Cotton candy candy canes marzipan carrot
              cake. Chocolate bar danish icing sweet apple pie jelly-o carrot cake cookie cake.
            </b-card-text>
            <b-card-text>
              Sesame snaps lemon drops candy marzipan donut brownie tootsie roll. Icing croissant bonbon biscuit gummi bears.
            </b-card-text>
          </b-tab>
          <b-tab title="Tab 3">
            <b-card-text>
              Carrot cake dragée chocolate. Lemon drops ice cream wafer gummies dragée. Chocolate bar liquorice cheesecake
              cookie chupa chups marshmallow oat cake biscuit.
            </b-card-text>
            <b-card-text> Dessert toffee fruitcake ice cream powder tootsie roll cake. </b-card-text>
          </b-tab>
        </b-tabs>
      </b-tab>

      <!-- left position -->
      <b-tab active title="Left">
        <b-tabs vertical nav-wrapper-class="nav-vertical">
          <b-tab active title="Tab 1">
            <b-card-text>
              Oat cake marzipan cake lollipop caramels wafer pie jelly beans. Icing halvah chocolate cake carrot cake. Jelly
              beans carrot cake marshmallow gingerbread chocolate cake. Sweet fruitcake cheesecake biscuit cotton candy.
              Cookie powder marshmallow donut. Gummies cupcake croissant.
            </b-card-text>
            <b-card-text>
              Sweet chocolate muffin fruitcake gummies jujubes pie lollipop. Brownie marshmallow caramels gingerbread jelly
              beans chocolate bar oat cake wafer.
            </b-card-text>
          </b-tab>
          <b-tab title="Tab 2">
            <b-card-text>
              Sugar plum tootsie roll biscuit caramels. Sweet fruitcake cheesecake biscuit cotton candy. Cookie powder
              marshmallow donut. Liquorice brownie pastry cotton candy oat cake fruitcake jelly chupa chups. Pudding caramels
              pastry powder cake soufflé wafer caramels. Jelly-o pie cupcake.
            </b-card-text>
            <b-card-text> Chocolate bar danish icing sweet apple pie jelly-o carrot cake cookie cake. </b-card-text>
          </b-tab>
          <b-tab title="Tab 3">
            <b-card-text>
              Sweet fruitcake cheesecake biscuit cotton candy. Cookie powder marshmallow donut. Biscuit ice cream halvah
              candy canes bear claw ice cream cake chocolate bar donut. Toffee cotton candy liquorice. Oat cake lemon drops
              gingerbread dessert caramels. Sweet dessert jujubes powder sweet sesame snaps.
            </b-card-text>
            <b-card-text>
              Candy cookie sweet roll bear claw sweet roll. Cake tiramisu cotton candy gingerbread cheesecake toffee cake.
              Cookie liquorice dessert candy canes jelly.
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-tab>
    </b-tabs>

    <template #code>
      {{ codePosition }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code';
import { BTabs, BTab, BCardText } from 'bootstrap-vue';
import { codePosition } from './code';

export default {
  components: {
    BCardCode,
    BCardText,
    BTabs,
    BTab,
  },
  data() {
    return {
      codePosition,
    };
  },
};
</script>
